$(function() {
    $('form:not(#popup_newsletter)').each(function(key, form) {
        $(form).validate({
            errorPlacement: function(error, element) {},
            highlight: function(element) {
                $(element).closest('.form-group, .input-group').addClass("has-error");
                $(element).parents('.box-validate').addClass("has-error");
                $(form).addClass('invalid');
            },
            unhighlight: function(element) {
                $(element).closest('.form-group, .input-group').removeClass("has-error");
                $(element).parents('.box-validate').removeClass("has-error");
                $(form).removeClass('invalid');
            }
        });
    });
});